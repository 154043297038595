import { Component, OnInit, OnDestroy } from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AccountService } from '../../services/account.service';
import { TradingService } from '../../services/trading.service';
import { DataShareService } from '../../services/data-share.service';
import { NGXLogger } from 'ngx-logger';
import { LoggerService } from '../../services/logger.service';
import { Socket } from 'ngx-socket-io';

declare var $: any;

@Component({
  selector: 'app-ai-trading',
  templateUrl: './ai-trading.component.html',
  styleUrls: ['./ai-trading.component.css']
})
export class AiTradingComponent implements OnInit, OnDestroy {

  // fees
  burnFee: number = 1.5;

  openMenuIndex;
  dateIndex;
  isMenuOpen = false;
  selectedRowIndex = 0;

  wallets = [];

  // pagination
  Arr = Array;
  Math = Math;
  maxRows = 100;
  currentPage = 1;
  pageSize = 6;
  totalPages;

  // row configuration
  rows = [{
    symbol: "",
    exchange: "",
    aiStatus: "",
    aiAdvice: "",
    wallet_name: "",
    wallet_address: "",
    lastPrice: "",
    change: "",
    date: "",
    volume: 0,
    capital: 0,
    lastOrderType: "",
    signalCount: 0
  }];

  // AI Integration
  aiStatus;
  exchange;
  userid;
  username;

  // Order ticket
  tickerSymbol;
  action;
  orderType;
  wallet_address;
  price;
  quantity;
  capital: any;
  email;

  // feature
  AT_FEATURE_ID: number = 1;

  // logger
  logger: NGXLogger;
  constructor(
    private accountService: AccountService,
    private tradingService: TradingService,
    private hotkeysService: HotkeysService,
    private dataShareService: DataShareService,
    private socket: Socket,
    private LoggerService: LoggerService
  ) { }


  ngOnInit() {

    // setup logger
    this.logger = this.LoggerService.create();

    // Setting up local vars
    this.rows = JSON.parse(localStorage.getItem("rows"));
    this.userid = JSON.parse(localStorage.getItem("auth_user")).id;
    this.username = JSON.parse(localStorage.getItem("auth_user")).username;
    this.wallets = JSON.parse(localStorage.getItem("wallets"));
    this.email = JSON.parse(localStorage.getItem('auth_user')).email;

    // Init hotkeys config
    this.hotKeysConfiguration();

    // Add a row on init
    if (this.rows.length == 0) {
      this.addRow();
    }

    // pagination
    this.setTotalPages();

    // load wallet info if not available
    if (!this.wallets.length) {
      this.accountService.walletInfo().subscribe(data => {
        if (data.error) {
          this.logger.error('Error while loading wallet info!', data);
        } else {
          this.wallets = data;
          localStorage.setItem("wallets", JSON.stringify(this.wallets));
        }
      })
    }

    // Get Auto Trading rows
    this.tradingService.getAutoTradingOrders();

    this.tradingService.getDayTradingOrders();

    // listen for AI Advice
    this.socket.on('ai-advice', (data) => {

      console.log('In ai advice', data)
      console.log('ai adive', data.signal)
      console.log('ai type', data.type)
      // AI Advice Response
      if (data.signal && data.type == 1) {
        let rowIndex = this.getSymbolIndex(data.symbol);
        let row = JSON.parse(localStorage.getItem("rows"))[rowIndex];

        this.logger.debug('AI Signal received', data);

        if (row) {
          // update the signal             
          this.rows[rowIndex].aiAdvice = data.signal;
          this.rows[rowIndex].date = data.date;
          localStorage.setItem("rows", JSON.stringify(this.rows));
        }
        else {
          this.logger.debug('No row found for the signal of:', data.symbol);
        }

      }
    })

    // listen for auto-trading rows;
    this.socket.on('at-rows', (res) => {
      if (res.success) {
        for (let symbol in res.data) {
          let data = JSON.parse(res.data[symbol]);
          let doesExists = false;

          for (let i = 0; i < this.rows.length; i++) {
            if (this.rows[i].symbol == symbol) {
              this.logger.debug('Updating auto trading row for:', symbol);

              this.rows[i].exchange = data.exchange;
              this.rows[i].aiStatus = data.aiStatus;
              this.rows[i].aiAdvice = data.aiAdvice;
              this.rows[i].wallet_name = data.wallet_name;
              this.rows[i].wallet_address = data.wallet_address;
              this.rows[i].date = data.date;
              this.rows[i].volume = data.volume;
              this.rows[i].capital = data.capital;
              this.rows[i].lastPrice = data.lastPrice;
              this.rows[i].change = data.change;
              this.rows[i].lastOrderType = data.lastOrderType;
              this.rows[i].signalCount = data.signalCount;

              localStorage.setItem("rows", JSON.stringify(this.rows));

              doesExists = true;
              break;
            }
          }

          if (!doesExists) {
            this.logger.debug('Adding auto trading row for: ', symbol);

            this.rows.push({
              symbol: symbol,
              exchange: data.exchange,
              aiStatus: data.aiStatus,
              aiAdvice: data.aiAdvice,
              wallet_name: data.wallet_name,
              wallet_address: data.wallet_address,
              lastPrice: data.lastPrice,
              change: data.change,
              date: data.date,
              volume: data.volume,
              capital: data.capital,
              lastOrderType: data.lastOrderType,
              signalCount: data.signalCount,
            });


            localStorage.setItem("rows", JSON.stringify(this.rows));
          }
        }
      }

      this.setTotalPages();
    });

    // listen for day-trading rows;
    this.socket.on('dt-rows', (res) => {
      if (res.success) {
        for (let symbol in res.data) {
          let data = JSON.parse(res.data[symbol]);
          let doesExists = false;

          for (let i = 0; i < this.rows.length; i++) {
            if (this.rows[i].symbol == symbol) {
              this.logger.debug('Updating day trading row for:', symbol);

              this.rows[i].exchange = data.exchange;
              this.rows[i].aiStatus = data.aiStatus;
              this.rows[i].aiAdvice = data.aiAdvice;
              this.rows[i].wallet_name = data.wallet_name;
              this.rows[i].wallet_address = data.wallet_address;
              this.rows[i].date = data.date;
              this.rows[i].volume = data.volume;
              this.rows[i].capital = data.capital;
              this.rows[i].lastPrice = data.lastPrice;
              this.rows[i].change = data.change;
              this.rows[i].lastOrderType = data.lastOrderType;
              this.rows[i].signalCount = data.signalCount;

              localStorage.setItem("rows", JSON.stringify(this.rows));

              doesExists = true;
              break;
            }
          }

          if (!doesExists) {
            this.logger.debug('Adding day trading row for: ', symbol);

            this.rows.push({
              symbol: symbol,
              exchange: data.exchange,
              aiStatus: data.aiStatus,
              aiAdvice: data.aiAdvice,
              wallet_name: data.wallet_name,
              wallet_address: data.wallet_address,
              lastPrice: data.lastPrice,
              change: data.change,
              date: data.date,
              volume: data.volume,
              capital: data.capital,
              lastOrderType: data.lastOrderType,
              signalCount: data.signalCount,
            });


            localStorage.setItem("rows", JSON.stringify(this.rows));
          }
        }
      }

      this.setTotalPages();
    });

    // auto trading cancel
    this.socket.on('at-stop', (res) => {
      this.logger.debug('cancel auto-trading: ', res);
      if (res.success) {
        for (let i = 0; i < this.rows.length; i++) {
          if (this.rows[i].symbol == res.symbol && this.rows[i].lastOrderType == "Auto Trading") {
            // remove the row
            this.rows.splice(i, 1);
            localStorage.setItem("rows", JSON.stringify(this.rows));
          }
        }
      }
    });

    // day trading cancel
    this.socket.on('dt-stop', (res) => {
      this.logger.debug('cancel day-trading: ', res);
      if (res.success) {
        for (let i = 0; i < this.rows.length; i++) {
          if (this.rows[i].symbol == res.symbol && this.rows[i].lastOrderType == "Day Trading") {
            // remove the row
            this.rows.splice(i, 1);
            localStorage.setItem("rows", JSON.stringify(this.rows));
          }
        }
      }
    });

    // log auto trading status
    this.socket.on('at-status', (res) => {
      this.logger.debug('auto-trading-status: ', res);

      if (res.success) {
        this.dataShareService.showAlert({ title: 'Success', body: 'Order is submitted successfully!' });
      }
    });

    // log day trading status
    this.socket.on('dt-status', (res) => {
      this.logger.debug('day-trading-status: ', res);

      if (res.success) {
        this.dataShareService.showAlert({ title: 'Success', body: 'Order is submitted successfully!' });
      }
    });

    // log order status
    this.socket.on('order-status', (res) => {
      this.logger.debug('auto-trading-status: ', res);

      if (res.success) {
        this.dataShareService.showAlert({ title: 'Success', body: 'Order is submitted successfully!' });
      }
    });

    // log advice status
    this.socket.on('advice-status', (res) => {
      this.logger.debug('get-advice status: ', res);
    });

    // log advice status
    this.socket.on('advice-stop-status', (res) => {
      this.logger.debug('advice-stop status: ', res);
    });
  }

  ngOnDestroy() {
    this.socket.removeAllListeners('ai-advice');
    this.socket.removeAllListeners('at-rows');
    this.socket.removeAllListeners('dt-rows');
    this.socket.removeAllListeners('at-stop');
    this.socket.removeAllListeners('dt-stop');
    this.socket.removeAllListeners('at-status');
    this.socket.removeAllListeners('dt-status')
    this.socket.removeAllListeners('order-status');
    this.socket.removeAllListeners('advice-status');
    this.socket.removeAllListeners('advice-stop-status');
  }


  // ---------------------------------------------------------------------
  //  Order & AI Advice Helper Methods
  // ---------------------------------------------------------------------
  async createOrder(tradeType) {

    console.log('Trade Type', tradeType)
    // Order Ticket Validation 
    if (!this.aiStatus) {
      this.dataShareService.showAlert({ title: 'Error', body: 'Please choose AI Status to place an order!' });
      return;
    }

    if (this.aiStatus == "Auto Trading" || this.aiStatus == "Day Trading") {
      if (!this.wallet_address || !this.capital) {
        this.dataShareService.showAlert({ title: 'Error', body: 'Wallet Name or Capital should not be empty or incorrect!' });
        return;
      }
    }

    // console.log('Exchange:' , this.exchange)

    if (this.aiStatus == "Day Trading" && this.exchange != 'NASDAQ' && this.exchange != 'NYSE') {
      this.dataShareService.showAlert({ title: 'Error', body: 'Day Trading service is not available for this exchange select another Ai Status' });
      return;
    }

    if (this.aiStatus == "None") {
      if (this.orderType == "Limit") {
        if (!this.action || !this.price || !this.quantity || !this.wallet_address) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Please enter all the required fields with valid values!' });
          return;
        }
      }
      if (this.orderType == "Market") {
        if (!this.action || !this.quantity || !this.wallet_address) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Please enter all the required fields with valid values!' });
          return;
        }
      }
    }

    // required vars
    var wallet_name = this.getWalletName(this.wallet_address);
    var rowIndex = this.getSymbolIndex(this.tickerSymbol);

    // wallet validation
    if (wallet_name == "q" || wallet_name == "Q") {
      this.dataShareService.showAlert({ title: 'Error', body: 'Wallet Q cannot be used for trading. Please try again with different wallet.' });
      return;
    }

    // --------------------
    //  Limit Order
    // --------------------
    if (this.orderType == "Limit" && this.aiStatus != "Auto Trading" && this.aiStatus != "Day Trading") {

      // calculate capital
      this.capital = await this.calculateCapital(this.exchange, this.price, this.quantity);

      // check if valid capital
      if (isNaN(this.capital)) {
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while calculating capital. Please try again.' });
        return;
      }

      // check if user have sufficient balance to place an order
      try {
        let balance = await this.accountService.getUSDBalance(this.wallet_address);
        console.log('1')
        if (balance < this.capital) {
          this.dataShareService.showAlert({ title: 'Error', body: 'you have insufficient balance to place this order! required balance: {{$1}} usd', translationParams: { $1: this.capital } });
          return;
        }
      } catch (e) {
        this.logger.error('Error while fetching the USD balance', e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting the wallet balance. Please try again later.' });
        return;
      }

      let order = {
        stock_symbol: this.tickerSymbol,
        type: tradeType,
        exchange: this.exchange,
        limit_price: this.price,
        volume: this.quantity,
        walletName: wallet_name,
        walletAddress: this.wallet_address,
        capital: this.capital,
      }

      // send order
      let hasPlaced = await this.sendOrder(order, "LIMIT");

      if (hasPlaced) {
        // update row
        this.rows[rowIndex].lastOrderType = 'Limit';
        this.rows[rowIndex].signalCount = 0;
        this.updateRow(rowIndex);
        this.resetOrderTicket();
      } else {
        this.deleteRow(rowIndex);
      }

    }

    // --------------------
    //  Market Order
    // --------------------
    if (this.orderType == "Market" && this.aiStatus != "Auto Trading" && this.aiStatus != "Day Trading") {

      // lot-size validation for Asian Markets
      if (this.exchange == 'SHA' || this.exchange == 'SHE' || this.exchange == 'SEHK') {

        // fetch lot-size
        try {
          var lot_size = await this.tradingService.fetchLotSize(this.exchange, this.tickerSymbol);
          this.logger.debug('Lot size:', lot_size);
        } catch (e) {
          this.logger.error('Error while fetching lot size:', e);
          this.dataShareService.showAlert({ title: 'Error', body: e });
          return;
        }

        if (this.quantity < lot_size) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Minimum quantity is {{$1}}. Kindly increase your capital to place order!', translationParams: { $1: lot_size } });
          return;
        }

        if (this.quantity % 100 != 0) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Order size {{$1}} is not correct. It should be multiple of 100.', translationParams: { $1: this.quantity } });
          return;
        }

      }

      // fetching stock price
      try {
        this.price = await this.tradingService.fetchStockPrice(this.exchange, this.tickerSymbol);
      } catch (e) {
        this.logger.error('Error while fetching price:', e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while fetching the price! Please try again.' });
        return;
      }

      // calculate capital
      this.capital = await this.calculateCapital(this.exchange, this.price, this.quantity);

      // check if valid capital
      if (isNaN(this.capital)) {
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while calculating capital. Please try again.' });
        return;
      }

      // calculate net position from RDS
      var netPosition: any = await this.tradingService.calculateNetPosition(this.tickerSymbol);
      this.logger.debug('Net Position:', netPosition);

      // net position = 0, Open position. check Silver balance.
      if (netPosition === 0) {
        // check if user have sufficient balance to place an order
        this.logger.debug('Checking capital for open position ...');

        try {
          let balance = await this.accountService.getUSDBalance(this.wallet_address);
          console.log('2')
          if (balance < this.capital) {
            this.dataShareService.showAlert({ title: 'Error', body: 'you have insufficient balance to place this order! required balance: {{$1}} usd', translationParams: { $1: this.capital } });
            return;
          }
        } catch (e) {
          console.error(e);
          this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting the wallet balance. Please try again later.' });
          return;
        }
      } else {
        // Open position (netPosition > 0 = BUY, netPosition < 0 = SELL)
        if ((tradeType == 'BUY' && netPosition > 0) || (tradeType == 'SELL' && netPosition < 0)) {
          this.logger.debug('Checking capital for open position ...');
          try {
            let balance = await this.accountService.getUSDBalance(this.wallet_address);
            console.log('3')
            if (balance < this.capital) {
              this.dataShareService.showAlert({ title: 'Error', body: 'you have insufficient balance to place this order! required balance: {{$1}} usd', translationParams: { $1: this.capital } });
              return;
            }
          } catch (e) {
            console.error(e);
            this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting the wallet balance. Please try again later.' });
            return;
          }
        }

        // Close position
        if ((tradeType == 'SELL' && netPosition > 0) || (tradeType == 'BUY' && netPosition < 0)) {
          this.logger.debug('Checking net position for close position ...');
          console.log('4')
          // check if insufficient volume (making netPosition positive using Math.abs to compare with quantity)
          if (Math.abs(netPosition) < this.quantity) {
            this.dataShareService.showAlert({ title: 'Error', body: 'You have insufficient position to place this order' });
            return;
          }
        }
      }


      let order = {
        stock_symbol: this.tickerSymbol,
        type: tradeType,
        exchange: this.exchange,
        volume: this.quantity,
        walletName: wallet_name,
        walletAddress: this.wallet_address,
        capital: this.capital,
      }

      // send order
      let hasPlaced = await this.sendOrder(order, "MARKET");

      if (hasPlaced) {
        // update the row
        this.rows[rowIndex].lastPrice = this.price;
        this.rows[rowIndex].lastOrderType = 'Market';
        this.rows[rowIndex].signalCount = 0;
        this.updateRow(rowIndex);
        this.resetOrderTicket();
      } else {
        // delete row
        this.deleteRow(rowIndex);
      }
    }

    // --------------------
    //  Auto Trading  Order And Day Trading Order
    // --------------------
    if (this.aiStatus == "Auto Trading" || this.aiStatus == "Day Trading") {

      // check if AT is blocked for an user
      try {
        if (this.aiStatus == "Auto Trading" && !await this.isAutoTradingEnabled()) {
          return this.dataShareService.showAlert({ title: 'Error', body: 'Auto Trading feature is locked. Please contact support center.' });
        }
      } catch (error) {
        return this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting Auto Trading feature status. Please try again.' });
      }

      try {
        if (this.aiStatus == "Day Trading" && !await this.isAutoTradingEnabled()) {
          return this.dataShareService.showAlert({ title: 'Error', body: 'Day Trading feature is locked. Please contact support center.' });
        }
      } catch (error) {
        return this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting Day Trading feature status. Please try again.' });
      }

      // calculate net position from RDS
      var netPosition: any = await this.tradingService.calculateNetPosition(this.tickerSymbol);
      this.logger.debug('Net Position:', netPosition);

      // making position positive
      netPosition = Math.abs(netPosition);

      if (netPosition !== 0) {
        if (this.aiStatus == "Day Trading") {
          this.dataShareService.showAlert({ title: 'Error', body: 'Please close the position before placing an Day Trading order.' });
        } else {
          this.dataShareService.showAlert({ title: 'Error', body: 'Please close the position before placing an Auto Trading order.' });
        }
        
        return;
      }

      // fetching stock price
      try {
        this.price = await this.tradingService.fetchStockPrice(this.exchange, this.tickerSymbol);
      } catch (e) {
        console.error('Error while fetching price:', e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while fetching the price! Please try again.' });
        return;
      }

      var volume;
      // OPEN POSITION Capital if balance = 0
      if (netPosition === 0) {
        // here the capital is not user's balance but the amount entered to open position
        // convert the currency for non-us market 
        this.capital = await this.tradingService.forexConversion(this.exchange, this.capital, false);

        volume = Math.round((this.capital / 1.1) / this.price);
        this.logger.debug('Calculated volume for auto trading or day trading order is:', volume);
      }
      // CLOSE POSITION Capital if balance > 0
      // if(netPosition > 0 ) {
      //   volume = netPosition;
      // }


      // lot-size validation for Asian Markets
      if (this.exchange == 'SHA' || this.exchange == 'SHE' || this.exchange == 'SEHK') {

        // fetch lot-size
        try {
          var lot_size = await this.tradingService.fetchLotSize(this.exchange, this.tickerSymbol);
          this.logger.debug('Lot size:', lot_size);
        } catch (e) {
          this.logger.debug('Error while fetching lot size:', e);
          this.dataShareService.showAlert({ title: 'Error', body: e });
          return;
        }

        if (volume < lot_size) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Minimum quantity is {{$1}}. Kindly increase your capital to place order!', translationParams: { $1: lot_size } });
          return;
        }

        if (volume % 100 != 0) {
          volume = volume - (volume % 100);
          this.logger.debug('Adjusting volume to:', volume, 'to fit into lot size.');
        }

      }

      // calculate capital
      this.capital = await this.calculateCapital(this.exchange, this.price, volume);

      // check if valid capital
      if (isNaN(this.capital)) {
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while calculating capital. Please try again.' });
        return;
      }

      // check if user have sufficient balance to place an order
      this.logger.debug('Checking capital for open position ...');
      try {
        let balance = await this.accountService.getUSDBalance(this.wallet_address);
        this.capital = parseFloat(this.capital);
        console.log('5')
        if (balance < this.capital + this.burnFee) {
          this.dataShareService.showAlert({ title: 'Error', body: 'you have insufficient balance to place this order! required balance: {{$1}} usd', translationParams: { $1: (this.capital + this.burnFee) } });
          return;
        }
      } catch (e) {
        this.logger.debug(e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while getting the wallet balance. Please try again later.' });
        return;
      }

      if (this.aiStatus == "Auto Trading") {
        this.rows[rowIndex].aiStatus = "2"; // change AI status to Auto Trading
        this.rows[rowIndex].lastPrice = this.price;
        this.rows[rowIndex].capital = this.capital;
        this.rows[rowIndex].volume = volume;
        this.rows[rowIndex].lastOrderType = 'Auto Trading';
        this.rows[rowIndex].signalCount = 0;
        this.rows[rowIndex].wallet_address = this.wallet_address;
        this.rows[rowIndex].wallet_name = wallet_name;
        this.updateRow(rowIndex);
        this.resetOrderTicket();
      }

      if (this.aiStatus == "Day Trading") {
        console.log('Day Trading')
        this.rows[rowIndex].aiStatus = "5"; // change AI status to Day Trading
        this.rows[rowIndex].lastPrice = this.price;
        this.rows[rowIndex].capital = this.capital;
        this.rows[rowIndex].volume = volume;
        this.rows[rowIndex].lastOrderType = 'Day Trading';
        this.rows[rowIndex].signalCount = 0;
        this.rows[rowIndex].wallet_address = this.wallet_address;
        this.rows[rowIndex].wallet_name = wallet_name;
        this.updateRow(rowIndex);
        this.resetOrderTicket();
      }

      console.log('Row index:', this.rows[rowIndex])

      // Emit Auto Trading event
      console.log('Ai Status:', this.rows[rowIndex].aiStatus)
      if (this.rows[rowIndex].aiStatus == "2") {
        console.log('in AT')
        var hasPlaced = await this.sendOrder(this.rows[rowIndex], "AT");
      }

      if (this.rows[rowIndex].aiStatus == "5") {
        console.log('in DT')
        hasPlaced = await this.sendOrder(this.rows[rowIndex], "DT");
      }

      console.log('Has Placed:', hasPlaced)
      if (hasPlaced) {
        // Send request to get Auto Trading signal
        this.getAIAdvice(rowIndex, this.rows[rowIndex].wallet_address);
      } else {
        // delete row
        this.deleteRow(rowIndex);
      }
    }
  }

  // Send order to UI service
  sendOrder(order, type) {
    return new Promise((resolve, reject) => {
      let address = order.walletAddress || order.wallet_address;
      console.log('Order before -1:' , order)
      order.orderType = type
      order.email = this.email
      console.log('Order after -1:' , order)
      this.accountService.getWalletStatus(address).subscribe(data => {
        this.logger.debug('wallet status:', data);
        if (data.islocked || data.error) {
          this.dataShareService.showAlert({ title: 'Error', body: 'Can not place order. please contact the support center.' });
          resolve(false)
        } else {
          this.logger.info('Sent ' + type + ' order request to connect API', { req: order });
          if (type == "AT") {
            this.socket.emit("at-place", order);
          } else if (type == "DT") {
            console.log('Into dt socket')
            this.socket.emit("dt-place", order)
          } else {
            this.socket.emit("order-place", order);
            order.orderType = "MKT"
          }
          resolve(true);
        }
      }, err => {
        console.error('error while fetching wallet status:', err);
        resolve(false);
      })
    })
  }

  // Calculate capital using formula
  calculateCapital = async (exchange, price, quantity) => {
    var capital, baseCapital, commission;

    baseCapital = price * quantity;

    if (exchange == "NYSE" || exchange == "NASDAQ") {
      commission = ((0.008 * quantity > 1.5) ? (0.008 * quantity) : 1.5);
    } if (exchange == "TSE") {
      commission = ((0.015 * quantity > 1.5) ? (0.015 * quantity) : 1.5);
    } if (exchange == "SHE" || exchange == "SHA") {
      commission = ((0.001 * baseCapital > 20) ? 0.001 * baseCapital : 20);
    } if (exchange == "SEHK") {
      commission = ((0.001 * baseCapital > 25) ? 0.001 * baseCapital : 25);
    }

    capital = (baseCapital + commission);
    this.logger.info('calculating capital (without forex conversion):', { exchange: exchange, price: price, quantity: quantity, commission: commission })
    return this.tradingService.forexConversion(exchange, capital, true);
  }

  cancleAutoTrading(rowIndex) {
    // Cancelling Auto Trading order
    this.logger.debug('Cancelling Auto Trading for: ' + this.rows[rowIndex].symbol);
    this.socket.emit("at-stop", { symbol: this.rows[rowIndex].symbol, userId: this.userid });

    // reseting Auto Trading parameters
    this.rows[rowIndex].aiStatus = "4";
    this.rows[rowIndex].lastOrderType = "";
    this.rows[rowIndex].signalCount = 0;
    this.rows[rowIndex].capital = 0;
    this.rows[rowIndex].volume = 0;
    this.rows[rowIndex].wallet_address = "";
    this.rows[rowIndex].wallet_name = "";
    this.rows[rowIndex].aiAdvice = "";
    this.updateRow(rowIndex);
  }

  cancleDayTrading(rowIndex) {
    // Cancelling Day Trading order
    this.logger.debug('Cancelling Day Trading for: ' + this.rows[rowIndex].symbol);
    this.socket.emit("dt-stop", { symbol: this.rows[rowIndex].symbol, userId: this.userid, wallet_address: this.rows[rowIndex].wallet_address, exchange: this.rows[rowIndex].exchange });

    // reseting Auto Trading parameters
    this.rows[rowIndex].aiStatus = "4";
    this.rows[rowIndex].lastOrderType = "";
    this.rows[rowIndex].signalCount = 0;
    this.rows[rowIndex].capital = 0;
    this.rows[rowIndex].volume = 0;
    this.rows[rowIndex].wallet_address = "";
    this.rows[rowIndex].wallet_name = "";
    this.rows[rowIndex].aiAdvice = "";
    this.updateRow(rowIndex);
  }

  async fetchSymbolDetails(rowIndex) {
    if (this.rows[rowIndex].symbol && this.rows[rowIndex].exchange) {
      this.fetchStockMarket(rowIndex);
    }
  }

  async AIStatusAction(rowIndex, $event) {
    console.log(`AIStatusAction called with rowIndex: ${rowIndex} and status: ${this.rows[rowIndex].aiStatus}`);
    console.log('event action:', $event.target.value)
    let status = this.rows[rowIndex].aiStatus || ($event ? $event.target.value : null);
    console.log(`Computed status: ${status}`);
    if (status == 1) {
      // check for required data avaibility
      console.log('Log:', this.rows[rowIndex].wallet_address)
      if (this.rows[rowIndex].symbol && this.rows[rowIndex].exchange && this.rows[rowIndex].wallet_address) {
        let hasPrice = await this.fetchStockMarket(rowIndex);
        console.log('Test-AI -1 ')
        // only if stock price returned (correct symbol)
        if (hasPrice === true) {
          let success = await this.getAIAdvice(rowIndex, this.rows[rowIndex].wallet_address);

          if (!success) {
            console.log('AI advice failed')
            this.rows[rowIndex].aiStatus = "4"; // change AI status to None
            this.rows[rowIndex].wallet_address = "";
            this.rows[rowIndex].wallet_name = "";
          }
        }
      }

    }
    if (status == 3) {
      console.log('Status AI-3')
      if (this.rows[rowIndex].lastOrderType === 'Auto Trading') {
        console.log('Last row type' , this.rows[rowIndex].lastOrderType)
        this.rows[rowIndex].aiStatus = '3'; // Keep value as 3 if lastOrderType is "Auto Trading"
        this.cancleAutoTrading(rowIndex);
      } else if (this.rows[rowIndex].lastOrderType === 'Day Trading') {
        this.rows[rowIndex].aiStatus = '6'; // Set value to 6 if lastOrderType is "Day Trading"
        console.log('Last row type' , this.rows[rowIndex].lastOrderType)
        this.cancleDayTrading(rowIndex)
      }

      
    }

    if (status == 4) {
      if (this.rows[rowIndex].symbol && this.rows[rowIndex].exchange) {
        this.rows[rowIndex].wallet_address = "";
        this.rows[rowIndex].wallet_name = "";
        this.stopAIAdvice(rowIndex);
      }
    }
  }

  async getAIAdvice(rowIndex, wallet_address) {
    var data = {
      userid: this.userid,
      symbol: this.rows[rowIndex].symbol,
      exchange: this.rows[rowIndex].exchange,
      ai_status: this.rows[rowIndex].aiStatus,
      aiAdvice: "",
      date: "",
      wallet_name: this.rows[rowIndex].wallet_name,
      wallet_address: this.rows[rowIndex].wallet_address,
    }

    var capital: any;

    return new Promise(async (resolve) => {
      try {
        this.accountService.getWalletStatus(wallet_address).subscribe(async response => {
          console.log(`getAIAdvice called for rowIndex: ${rowIndex} and wallet_address: ${wallet_address}`);
          this.logger.debug('wallet status:', response);
          console.log('get wallet status response:', response)
          if (response.islocked || response.error) {
            // blocked
            this.dataShareService.showAlert({ title: 'Error', body: 'please contact the support center.' });
            resolve(false);
          } else {
            // functional
            capital = await this.accountService.getUSDBalance(wallet_address);
            console.log('total balance', capital)
            console.log("burn fee:", this.burnFee)
            console.log('6')
            // capital = 230
            if (capital < this.burnFee) {
              this.dataShareService.showAlert({ title: 'Error', body: 'You have insufficient balance to fetch AI Advice!' });
              resolve(false);
            } else {
              data.ai_status == "2" ?
                this.logger.debug('Getting Auto Trading Signal for: ' + data.symbol) :
                this.logger.debug('Getting AI Advice for: ' + data.symbol);

              // send ai advice data
              this.tradingService.getAIAdvice(data);
              resolve(true);
            }
          }
        }, err => {
          console.error('error while fetching wallet status:', err);
          resolve(false);
        })
      }
      catch (e) {
        console.log('Error while fetching AI Advice: ', e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Error while fetching AI Advice. Please try again' });
        resolve(false);
      }
    });


  }

  async stopAIAdvice(rowIndex) {

    let data = {
      userid: this.userid,
      symbol: this.rows[rowIndex].symbol,
      exchange: this.rows[rowIndex].exchange,
      ai_status: "4"
    }

    this.logger.debug('Stopping AI Advice for: ' + data.symbol);
    this.tradingService.stopAIAdvice(data);
  }

  async fetchStockMarket(rowIndex) {
    var price: any;
    var change: any;
    console.log(`fetchStockMarket called for rowIndex: ${rowIndex}`);

    return new Promise(async (resolve) => {
      try {
        price = await this.tradingService.fetchStockPrice(this.rows[rowIndex].exchange, this.rows[rowIndex].symbol);
        this.rows[rowIndex].lastPrice = parseFloat(price).toFixed(2) || this.rows[rowIndex].lastPrice;
      }
      catch (e) {
        console.error('Error while fetching price:', e);
        this.dataShareService.showAlert({ title: 'Error', body: 'Incorrect Symbol or Exchange! Please enter valid details.' });
        this.deleteRow(rowIndex);
        return resolve(false);
      }

      try {
        change = await this.tradingService.fetchStockPriceChange(this.rows[rowIndex].exchange, this.rows[rowIndex].symbol);
        this.rows[rowIndex].change = parseFloat(change).toFixed(2);
      }
      catch (e) {
        console.log('Error while fetching price change:', e);
      }

      this.updateRow(rowIndex);
      return resolve(true);
    })

  }

  isAutoTradingEnabled(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.accountService.getFeatureStatus(this.AT_FEATURE_ID).subscribe((res) => {
        if (res.success) {
          return resolve(res.status);
        } else {
          return reject(false);
        }
      }, (err) => {
        console.log('Error while getting AT feature status', err);
        return reject(false);
      })
    })
  }
  // ---------------------------------------------------------------------
  //  Utility Methods
  // ---------------------------------------------------------------------

  addRow() {

    // MAX Rows
    if (this.rows.length > this.maxRows) {
      return;
    }

    let row = {
      symbol: "",
      exchange: "",
      aiStatus: "",
      aiAdvice: "",
      wallet_name: "",
      wallet_address: "",
      lastPrice: "",
      change: "",
      date: "",
      volume: 0,
      capital: 0,
      lastOrderType: "",
      signalCount: 0
    }

    this.rows.push(row);
    let rows = JSON.parse(localStorage.getItem("rows"));
    rows.push(row);
    localStorage.setItem("rows", JSON.stringify(rows));

    this.setTotalPages();
  }

  deleteRow(index) {
    this.rows.splice(index, 1);
    localStorage.setItem("rows", JSON.stringify(this.rows));

    if (this.rows.length == 0) {
      this.addRow();
    }

    this.setTotalPages();
    this.resetPage();
  }

  checkDuplicate(rowIndex) {
    if (this.rows[rowIndex].symbol.trim() == "") {
      return;
    }

    const duplicate = this.rows.filter(row => row.symbol.toUpperCase() === this.rows[rowIndex].symbol.toUpperCase()).length > 1 ? true : false;

    if (duplicate) {
      this.dataShareService.showAlert({ title: 'Error', body: 'A row already exists for symbol: {{$1}}', translationParams: { $1: this.rows[rowIndex].symbol } });
      this.deleteRow(rowIndex);
    }
  }

  sendAutoTradingNotification(position, signal, row) {
    let notification = {
      type: "auto_trading",
      data: {
        position: position,
        symbol: row.symbol,
        exchange: row.exchange,
        signal: signal,
        volume: row.volume,
        capital: row.capital,
        wallet_name: row.wallet_name
      }
    }

    this.accountService.sendNotification(notification).subscribe(res => {
      if (res.success) {
        this.logger.debug('Successfully sent AT notification', notification, res);
      } else {
        this.logger.error('Error while sending the AT notification: ', notification, res.error);
      }
    })
  }

  setTickerSymbol(symbol) {
    this.tickerSymbol = symbol;
  }

  selectSymbol(selectedItem, i) {
    if (selectedItem) {
      // return if symbol is already in rows
      if (this.rows.some(e => e.symbol === selectedItem.title)) {
        return;
      }
      this.rows[i].symbol = selectedItem.title;
    }
  }

  openMenu(rowIndex) {
    this.openMenuIndex = rowIndex;
    this.isMenuOpen = true;
    this.resetOrderTicket();
    this.updateTickerSymbol(this.rows[rowIndex].symbol);
    this.updateExchange(this.rows[rowIndex].exchange);
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  resetOrderTicket() {
    this.tickerSymbol = null;
    this.action = null;
    this.orderType = null;
    this.price = null;
    this.quantity = null;
    this.aiStatus = null;
    this.capital = null;
    this.wallet_address = null;
  }

  updateTickerSymbol(symbol) {
    this.tickerSymbol = symbol;
  }

  updateExchange(exchange) {
    this.exchange = exchange;
  }

  updateRow(rowIndex) {

    // uppercase
    this.rows[rowIndex].symbol = this.rows[rowIndex].symbol.toUpperCase();

    // update wallet name
    if (this.rows[rowIndex].wallet_address) {
      this.rows[rowIndex].wallet_name = this.getWalletName(this.rows[rowIndex].wallet_address);
    }

    let rows = JSON.parse(localStorage.getItem("rows"));
    rows[rowIndex] = this.rows[rowIndex];
    localStorage.setItem("rows", JSON.stringify(rows));

  }

  getSymbolIndex(sym) {

    let rows = JSON.parse(localStorage.getItem("rows"));
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].symbol == sym)
        return i;
    }

    return -1;
  }

  getWalletName(addr) {
    for (let i = 0; i < this.wallets.length; i++) {
      if (this.wallets[i].wallethash == addr) {
        return this.wallets[i].wallet_name;
      }
    }
  }

  hotKeysConfiguration() {
    let hotkeys = JSON.parse(localStorage.getItem("hotKeys"));
    if (hotkeys) {
      // Buy
      this.hotkeysService.add(new Hotkey(hotkeys.buy, (event: KeyboardEvent): boolean => {
        this.action = 'BUY';
        document.getElementById('orderTicketButton').click();
        return false;
      }, ['INPUT']));
      // Sell
      this.hotkeysService.add(new Hotkey(hotkeys.sell, (event: KeyboardEvent): boolean => {
        this.action = 'SELL';
        document.getElementById('orderTicketButton').click();
        return false;
      }, ['INPUT']));
      // Close 
      this.hotkeysService.add(new Hotkey(hotkeys.close, (event: KeyboardEvent): boolean => {
        return false;
      }, ['INPUT']));
      // Insert Row
      this.hotkeysService.add(new Hotkey(hotkeys.insert, (event: KeyboardEvent): boolean => {
        this.addRow();
        return false;
      }, ['INPUT']));
      // Delete
      this.hotkeysService.add(new Hotkey(hotkeys.delete, (event: KeyboardEvent): boolean => {
        this.deleteRow(this.selectedRowIndex);
        return false;
      }, ['INPUT']));
    }
  }

  // pagination
  setTotalPages = () => {
    this.totalPages = Math.ceil(this.rows.length / this.pageSize);
  }

  resetPage = () => {
    if (this.currentPage > this.totalPages) {
      this.currentPage -= 1;
    }
  }
}